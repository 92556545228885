<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-row>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ total_count }}</h2>
                <p class="fontsize-sm m-0">
                  Total Raw Data
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ pending_count }}</h2>
                <p class="fontsize-sm m-0">
                  Pending Raw Data
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ invalid_count }}</h2>
                <p class="fontsize-sm m-0">
                  Invalid Raw Data
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ processed_count }}</h2>
                <p class="fontsize-sm m-0">
                  Processed Raw Data
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ call_request_count }}</h2>
                <p class="fontsize-sm m-0">
                  Pending Call Request
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col
        cols="12"
        lg="6"
      >
        <iq-card>
          <template v-slot:body>
            <div class="d-flex d-flex align-items-center justify-content-between">
              <div>
                <h2>{{ visit_request_count }}</h2>
                <p class="fontsize-sm m-0">
                  Pending Visit Request
                </p>
              </div>
              <div class="rounded-circle iq-card-icon  dark-icon-light iq-bg-success">
                <i class="ri-inbox-fill" /></div>
            </div>
          </template>
        </iq-card>
      </b-col>

    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { UserDashboardService } from '@/services'

export default {
  name: 'UserDashboard',

  middleware: ['auth', 'user'],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      total_count: 0,
      pending_count: 0,
      invalid_count: 0,
      processed_count: 0,
      call_request_count: 0,
      visit_request_count: 0
    }
  },

  mounted () {
    core.index()
    this.fetchData()
  },

  methods: {
    async fetchData () {
      await UserDashboardService.getStatistics().then(({ data }) => {
        this.total_count = data.total_count
        this.pending_count = data.pending_count
        this.invalid_count = data.invalid_count
        this.processed_count = data.processed_count
        this.call_request_count = data.call_request_count
        this.visit_request_count = data.visit_request_count
      })
    }
  }
}
</script>
